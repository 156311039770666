@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    background-color: #000;
}

.mainPage {
    width: 100vw;
    height: 100vh;
    background-image: url("./images/background.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.topDiv {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    background-color: #000;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 100px;
    opacity: 0.8;
}

.topDiv_img {
    width: 150px;
    height: 150px;
    background-color: #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topDiv_img img {
    width: 100%;
    height: 100%;
}

.topDiv>h1 {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-family: sans-serif;
    text-align: center;
    color: #FCBF0C;
    font-size: 50px;
    line-height: 1.15;
    text-transform: uppercase;
}

.topDiv h1>span {
    color: #edf2ff;
}

.topDiv_socialNetwork {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    gap: 25px;
    padding-top: 30px;
}

.topDiv_socialNetwork h1 {
    font-size: 30px;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-family: sans-serif;
    text-align: center;
    color: #FCBF0C;
    line-height: 1.15;
    text-transform: uppercase;
}

.topdiv_social_Network_btn {
    width: 95%;
    display: flex;
    background-color: transparent;
    justify-content: space-between;
}

.topdiv_social_Network_btn a {
    width: 23%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    border: 2px solid #fcc00c94;
    border-radius: 5px;
    color: #FCBF0C;
    background-color: transparent;
    cursor: pointer;
    text-decoration: none;
}

.topdiv_social_Network_btn a:hover {
    border: 2px solid #fcc00c94;
    color: #edf2ff;
    background-color: #FCBF0C;
}

.topdiv_social_Network_btn a:hover svg {
    background-color: #FCBF0C;
}

.topDiv_Collection {
    width: 90%;
    color: #fff;
    display: flex;
    flex-direction: column;
    margin: 20px;
    gap: 50px;
}

.topDiv_Collection p {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
}

.topDiv_Collection ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 10px;
}

.topDiv_Collection li {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.admin_collection_btn {
    width: 90%;
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 30px;
    border: 2px solid #fcc00c94;
    border-radius: 5px;
    color: #FCBF0C;
    background-color: transparent;
    cursor: pointer;
    text-decoration: none;
    padding: 20px;
    flex-direction: row;
}

.admin_collection_btn:hover {
    color: #edf2ff;
    background-color: #FCBF0C;
}

.admin_collection_btn:hover svg {
    fill: #edf2ff;
    background-color: #FCBF0C;
    /* Change the fill color of SVG on hover */
}

.admin_collection_btn span {
    padding-left: 40%;
    font-size: 20px;
    background-color: transparent;
}

.admin_collection_btn span:hover {
    background-color: #FCBF0C;
}

.topDiv_Collection li svg {
    color: #FCBF0C;
    font-size: 25px;
}


.lastDiv_headerText {
    padding-top: 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;

}

.lastDiv_headerText>h1 {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-family: sans-serif;
    text-align: center;
    color: #FCBF0C;
    font-size: 38px;
    line-height: 1.15;
    text-transform: uppercase;
}

.lastDiv_headerText h1>span {
    color: #edf2ff;
}


.lastDiv_middleText {
    padding-top: 30px;
}

.lastDiv_middleText>h1 {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-family: sans-serif;
    text-align: center;
    color: #FCBF0C;
    font-size: 30px;
    line-height: 1.15;
    text-transform: uppercase;
}

.lastDiv_middleText h1>span {
    color: #edf2ff;
}

.lastDiv_headerText p {
    color: #edf2ff;
    text-align: center;
    font-size: 20px;
}

.lastDiv_headerText p>span {
    color: #FCBF0C;
}

.lastDiv_location {
    width: 70%;
    flex-direction: column;
    display: flex;
    gap: 30px;
}

.lastDiv_location a {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 20px;
    border: 2px solid #fcc00c94;
    border-radius: 5px;
    color: #FCBF0C;
    background-color: transparent;
    cursor: pointer;
    text-decoration: none;
    padding: 20px;
    flex-direction: row;
}

.lastDiv_location a:hover {
    border: 2px solid #fcc00c94;
    color: #edf2ff;
    background-color: #FCBF0C;
}

.lastDiv_location a:hover svg {
    background-color: #FCBF0C;
}


.lastDiv_location a:hover span {
    background-color: #FCBF0C;
}

.lastDiv_location a>svg {
    font-size: 24px;
}

.lastDiv_location a>span {
    padding-left: 34%;
}

.last_icon_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #edf2ff;
    text-decoration: none;
    font-size: 20px;
    padding: 20px;
    gap: 15px;
}



.last_icon_btn svg {
    font-size: 30px;
}

.last_icon_btn a {
    text-decoration: none;
    color: #edf2ff;
}

@media screen and (max-width:800px) {
    .lastDiv_location {
        width: 90%;
    }

    .mainPage {
        width: 100%;
    }
}

@media screen and (max-width:600px) {
    .lastDiv_location a>span {
        padding-left: 0%;
    }

    .lastDiv_location a {
        gap: 40px;
    }


    .admin_collection_btn span {
        padding-left: 0%;
    }

    .admin_collection_btn {
        gap: 40px;
    }

    .lastDiv_location a>span {
        padding-left: 0%;
    }
}